<script setup lang="ts">
import { defineProps, PropType, computed, ref } from 'vue'

import ImageWrapper from '@base/components/ImageWrapper/ImageWrapper.vue'
import VideoWrapper from '@base/components/VideoWrapper/VideoWrapper.vue'

import {
  Background,
  ResponsiveImageSet as BackgroundImageSet
} from '@base/_types/Background'

type StringValueDictionary = {
  [name: string]: string
}

const props = defineProps({
  background: {
    type: Object as PropType<Background>,
    default: null
  }
})

const colorValues = ['light', 'dark', 'none']
const alignHorizontalValues = [
  'left_0',
  'left_10',
  'left_25',
  'left_50',
  'left_75',
  'left_100',
  'center',
  'right_0',
  'right_10',
  'right_25',
  'right_50',
  'right_75',
  'right_100'
]
const alignVerticalValues = [
  'top_0',
  'top_10',
  'top_25',
  'top_50',
  'top_75',
  'top_100',
  'center',
  'bottom_0',
  'bottom_10',
  'bottom_25',
  'bottom_50',
  'bottom_75',
  'bottom_100'
]
const objectPositionHorizontalValues = ['left', 'center', 'right']
const objectPositionVerticalValues = ['top', 'center', 'bottom']

const isValidValue = (value: string | undefined | null, values: string[]) => {
  if (value) {
    return values.includes(value)
  } else {
    return false
  }
}

const computedColorClass = computed(() => {
  if (!props.background || !props.background.color) {
    return ''
  }

  const color = props.background.color
  if (color && isValidValue(color, colorValues)) {
    return `background-wrapper__color--${color}`
  } else {
    return ''
  }
})

const getResponsiveSrc = (
  imageSet: BackgroundImageSet
): { [key: string]: string }[] => {
  const srcs: { [key: string]: string }[] = []

  const mobileSrc = imageSet.mobile.src ? imageSet.mobile.src : ''
  const tabletSrc = imageSet.tablet?.src ? imageSet.tablet?.src : ''
  const desktopSrc = imageSet.desktop?.src ? imageSet.desktop?.src : ''

  // Mobile
  srcs.push({
    breakpoint: 'mobile',
    src: mobileSrc
  })

  // Tablet
  srcs.push({
    breakpoint: 'tablet',
    src: imageSet.tablet ? tabletSrc : mobileSrc // default mobile
  })

  // Desktop
  srcs.push({
    breakpoint: 'desktop',
    src: imageSet.desktop ? desktopSrc : mobileSrc // default mobile
  })

  return srcs
}

const computedObjectFit = computed(() => {
  let objectFits: string[] = []

  if (!props.background.media) return objectFits

  for (let media of props.background.media) {
    objectFits.push(media.position.cover ? 'cover' : 'contain')
  }

  return objectFits
})

const computedObjectPosition = computed(() => {
  let objectPositions: string[] = []

  if (!props.background.media) return objectPositions

  for (let media of props.background.media) {
    const hPosition = isValidValue(
      media.position.objectPositionHorizontal,
      objectPositionHorizontalValues
    )
      ? media.position.objectPositionHorizontal
      : 'center'

    const vPosition = isValidValue(
      media.position.objectPositionVertical,
      objectPositionVerticalValues
    )
      ? media.position.objectPositionVertical
      : 'center'

    objectPositions.push(`${hPosition} ${vPosition}`)
  }

  return objectPositions
})

const computedMediaDimensions = computed(() => {
  let mediaDimensions: StringValueDictionary[] = []

  if (!props.background.media) return mediaDimensions

  for (let [index, media] of props.background.media.entries()) {
    // Same dimensions as parent of BackgroundWrapper instance
    const mediaDimension: StringValueDictionary = {
      width: '100%',
      height: '100%',
      wrapperWidth: '100%',
      wrapperHeight: '100%'
    }

    // Same dimensions as intrinsic media dimensions
    let width: string | undefined = undefined
    let height: string | undefined = undefined

    if (media.type === 'image') {
      width = 'auto'
      height = 'auto'
    } else if (media.type === 'video') {
      width = media.video.width
      height = media.video.height
    }

    if (computedObjectFit.value[index] === 'contain') {
      if (width) mediaDimension.width = width
      if (height) mediaDimension.height = height
      mediaDimension.wrapperWidth = 'fit-content'
      mediaDimension.wrapperHeight = 'fit-content'
    }

    mediaDimensions.push(mediaDimension)
  }

  return mediaDimensions
})

const getAlignValue = (keyword: string): string => {
  return keyword.split('_')[1]
}

const computedAlignStyles = computed(() => {
  let alignStyles: StringValueDictionary[] = []

  if (!props.background.media) return alignStyles

  for (let [index, media] of props.background.media.entries()) {
    const alignStyle: StringValueDictionary = {
      bottom: 'auto',
      left: 'auto',
      right: 'auto',
      top: 'auto',
      transform: 'none'
    }

    const hAlign = media.position.alignHorizontal
    const vAlign = media.position.alignVertical

    if (
      !isValidValue(hAlign, alignHorizontalValues) ||
      !isValidValue(vAlign, alignVerticalValues)
    ) {
      alignStyles.push(alignStyle)
      continue
    }

    const centerAnchor = media.position.centerAnchor

    let left: string | null = null
    let right: string | null = null
    let top: string | null = null
    let bottom: string | null = null
    let translateX: string = '0'
    let translateY: string = '0'

    if (hAlign.startsWith('left')) {
      left = getAlignValue(hAlign)
      if (centerAnchor) translateX = '-50'
    } else if (hAlign.startsWith('right')) {
      right = getAlignValue(hAlign)
      if (centerAnchor) translateX = '50'
    } else if (hAlign === 'center') {
      left = '50'
      translateX = '-50'
    }

    if (vAlign.startsWith('top')) {
      top = getAlignValue(vAlign)
      if (centerAnchor) translateY = '-50'
    } else if (vAlign.startsWith('bottom')) {
      bottom = getAlignValue(vAlign)
      if (centerAnchor) translateY = '50'
    } else if (vAlign === 'center') {
      top = '50'
      translateY = '-50'
    }

    if (left !== null) alignStyle.left = `${left}%`
    if (right !== null) alignStyle.right = `${right}%`
    if (top !== null) alignStyle.top = `${top}%`
    if (bottom !== null) alignStyle.bottom = `${bottom}%`
    alignStyle.transform = `translate(${translateX}%, ${translateY}%)`

    alignStyles.push(alignStyle)
  }

  return alignStyles
})

let colorLayerZIndex = ref<number>(-1)

const computedZIndex = computed(() => {
  let zIndices: number[] = []

  if (!props.background.media) return zIndices

  // TODO: not sure if more elegant way to get count
  let nZIndexBackground = 0
  for (let media of props.background.media) {
    if (!media.position.foreground) {
      nZIndexBackground += 1
    }
  }
  colorLayerZIndex.value = -(1 + nZIndexBackground) // place as furthest back

  //
  const minZIndexForeground = 2
  const maxZIndexBackground = -nZIndexBackground
  let zIndexForeground = minZIndexForeground
  let zIndexBackground = maxZIndexBackground

  for (let media of props.background.media) {
    if (media.position.foreground) {
      zIndices.push(zIndexForeground)
      zIndexForeground += 1
    } else {
      zIndices.push(zIndexBackground)
      zIndexBackground += 1
    }
  }

  return zIndices
})
</script>

<template>
  <div data-test="background-wrapper" class="background-wrapper">
    <div :class="['background-wrapper__color', computedColorClass]" />
    <template v-if="background && background.media">
      <template v-for="(media, index) in background.media" :key="index">
        <div
          :class="[
            'background-wrapper__media',
            media.position.foreground
              ? 'background-wrapper__media--foreground'
              : 'background-wrapper__media--background'
          ]"
          :style="{
            left: computedAlignStyles[index].left,
            right: computedAlignStyles[index].right,
            top: computedAlignStyles[index].top,
            bottom: computedAlignStyles[index].bottom,
            transform: computedAlignStyles[index].transform,
            width: computedMediaDimensions[index].wrapperWidth,
            height: computedMediaDimensions[index].wrapperHeight,
            zIndex: computedZIndex[index]
          }"
        >
          <template v-if="media.type === 'image'">
            <template v-for="imageIndex in 3" :key="imageIndex">
              <!--prettier-ignore-->
              <ImageWrapper
                :class="[
                  'background-wrapper__image',
                  `background-wrapper__image--${
                    getResponsiveSrc(media.imageSet)[imageIndex - 1]['breakpoint']
                  }`
                ]"
                :alt="media.imageSet.mobile.alt"
                :aspect-ratio="media.imageSet.mobile.aspectRatio"
                :fade-in="true"
                :height="computedMediaDimensions[index].height"
                :lazy="media.imageSet.mobile.lazy"
                :longdesc="media.imageSet.mobile.longdesc"
                :object-fit="computedObjectFit[index]"
                :object-position="computedObjectPosition[index]"
                :src="getResponsiveSrc(media.imageSet)[imageIndex - 1]['src']"
                :title="media.imageSet.mobile.title"
                :width="computedMediaDimensions[index].width"
              />
            </template>
          </template>
          <template v-else-if="media.type === 'video'">
            <VideoWrapper
              class="background-wrapper__video"
              :aspect-ratio="media.video.aspectRatio"
              :autoplay="true"
              :controls="false"
              :height="computedMediaDimensions[index].height"
              :lazy-autoplay="true"
              :loop="media.video.loop"
              :muted="true"
              :object-fit="computedObjectFit[index]"
              :object-position="computedObjectPosition[index]"
              :poster="media.video.poster"
              :preload="media.video.preload"
              :sources="media.video.sources"
              :src="media.video.src"
              :width="computedMediaDimensions[index].width"
            />
          </template>
          <template v-else-if="media.type === 'html'">
            <div class="background-wrapper__html">
              <slot name="backgroundMediaHtml" />
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';
@use '@base/styles/v1.0/scss/foundations/color';

.background-wrapper {
  background-color: transparent;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &__color {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: v-bind(colorLayerZIndex);

    &--light {
      background-color: color.$color--neutral-a5;
    }

    &--dark {
      background-color: color.$color--primary-b2;;
    }
  }

  &__media {
    position: absolute;
  }

  // Hide and show according to viewport
  &__image {
    &--mobile {
      display: inline;

      @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
        display: none;
      }
    }

    &--tablet {
      display: none;

      @include breakpoint.min-width(breakpoint.$breakpoint--tablet) {
        display: inline;
      }

      @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include breakpoint.min-width(breakpoint.$breakpoint--desktop) {
        display: inline;
      }
    }
  }
}
</style>
